@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.searchpad {
	padding-top: 60px;


	@media (max-width: 992px) {
		padding-top: 130px;
	}
}

.search-layout {
	.containers {
		max-width: 1840px;
	}

	.map {
		display: block;

		@media (max-width:1200px) {
			display: none;
		}

	}

	/*
	.filter {
		min-width: 300px;
		width: 20%;
	}

	.list {
		min-width: 800px;
		width: 60%;
	}

	.map-section {
		min-width: 300px;
		width: 20%;
	} */
}

.search-form {
	background-color: var(--darkblue);
	color: var(--white);
	padding: 35px 0 0;

	.form-group {
		>label {
			font-weight: normal;
			font-size: 13px;
			color: var(--white);
		}

		#region-trigger {
			background-color: var(--white);
		}
	}

	.search-m-form {

		.form-control {
			padding: 7px 10px;
			height: 38px;
			min-height: 38px;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0 !important;
			/* background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;*/
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;

			@media (max-width: 767px) {
				height: auto;
			}
		}

		.Schlafzimmer {
			&:after {
				content: "\f078";
				font-family: FontAwesome;
				font-weight: 400;
				display: block;
				color: #9b9b9b;
				position: absolute;
				right: 28px;
				top: 35px;
			}
		}

		.Zeitraum {
			@media all (min-width: 993px) and (max-width: 1199px) {
				width: 40%;
			}
		}

		.Gaste {
			@media all (min-width: 993px) and (max-width: 1199px) {
				width: 30%;
			}
		}

		.Schlafzimmer-box {
			@media all (min-width: 993px) and (max-width: 1199px) {
				width: 30%;
			}
		}
	}



	.label-li {
		label {
			color: var(--grey);
		}
	}

	.asd__wrapper--full-screen {
		@media (max-width: 992px) {
			padding-top: 0;

		}


	}
}


.sidebar-search {
	background-color: var(--white);
	margin-bottom: 30px;

	.widgt-title {
		text-transform: uppercase;
		margin-bottom: 10px;
		display: block;
		color: var(--black);
		font-weight: 600;
		position: relative;

		a {
			float: right;
			text-transform: none;
			font-weight: 500;
			position: absolute;
			right: 0;
			width: auto;
			top: 0;
		}
	}

	.label-li {
		li {
			margin-bottom: 8px;

			.checkbox {
				margin: 0;
				font-size: 13px;
				color: var(--black5);
			}
		}
	}

	.form-control {
		margin-bottom: 10px;
		width: 100%;
		text-align: left !important;
	}
}

.available-dates {
	padding: 0 25px;
	margin-bottom: 15px;

	.owl-dt-bl {
		text-align: center;

		.dt-box {
			font-size: 13px;
			color: var(--white);
			letter-spacing: 1px;
			font-weight: bold;
			background-color: var(--secondary);
			padding: 5px;
			text-transform: uppercase;
		}

		.dt-cont-bl {
			padding: 30px 10px;
			background-color: var(--white);

			h3 {
				font-size: 19px;
				margin-bottom: 0;
				font-weight: 600;
			}
		}
	}

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		button {
			position: absolute;
			left: 0;
			color: #a4a4a4;

			span {
				font-size: 28px !important;
			}

			&.owl-next {
				position: absolute;
				right: 0;
				left: initial;
			}
		}
	}
}

.search-about-bl {
	background-color: var(--white);
	margin-bottom: 25px;

	.srch-bl-title {
		border-bottom: 1px solid #E5E5E5;
		padding: 10px 15px;
		text-transform: uppercase;
		font-weight: 600;
		margin: 0;
	}

	.srch-ab-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
		flex-wrap: wrap;
	}

	.search-rest-left {
		h3 {
			font-size: 25px;
			font-weight: 400;
			margin: 0 0 5px;
		}

		span {
			font-size: 15px;
			color: var(--darkgrey);
			margin-bottom: 5px;
		}
	}
}


.unit-srch-li {
	.results {
		>.row {
			margin: 0;
		}
	}
}


.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 3px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}

		.leaflet-popup-content {
			width: 270px !important;
			margin: 0 !important;
			padding: 15px;
		}

		.unit-serv-li {
			max-width: 110px;
			text-align: left;
			margin: 0 auto 15px;
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.unit-serv-li {
			i {
				margin-right: 5px;
			}
		}

		.unit-btm-dt {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-evenly;

			.fa {
				margin-right: 5px;
			}
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;


		}

		.preview-img {
			min-height: 140px;

			img {
				max-width: 99%;
				margin-bottom: 10px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;

		@media (min-width:768px) {
			display: none;
		}
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
			color: red;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.unit-main-row {
			margin-bottom: 15px;

			.alternatives {
				padding: 15px;
				position: relative;
				display: block;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--black);

				.li-bullet {
					list-style: disc;
					padding-left: 18px;
				}
			}

			.offer-box {
				padding: 15px;
				position: relative;
				display: flex;
				margin: 0;
				align-items: center;
				flex-wrap: nowrap;
				background-color: #fff;
				border-top: 1px solid #e5e5e5;
				color: var(--black);


				border-top: 1px solid #ebebeb;

				@media (max-width:767px) {
					display: block
				}

				.offer-left {
					background: var(--secondary);
					color: #fff;
					padding: 10px 15px;
					border: 1px dashed #fff;
					font-size: 16px;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					margin-right: 15px;

					@media (max-width:767px) {
						display: block;
						text-align: center;
						margin-right: 0;
						margin-bottom: 15px
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li {
						margin: 0 0 5px;
						line-height: 14px;
						font-size: 12px;

						.offer-name {
							font-weight: 600;
						}

						.offer-condition {
							font-style: italic;
						}
					}
				}
			}
		}

		.unit-row {
			width: 100%;
			position: relative;
			padding: 15px;
			background-color: #fff;

			.img-col {
				position: relative;
			}

			.map-marker {
				position: absolute;
				top: -15px;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.owl-carousel {
					overflow: hidden;
					height: 100%;

					.owl-stage-outer {
						height: 100%;

						.owl-stage {
							height: 100%;
						}

						.owl-item {
							height: 100%;

							.item {
								height: 100%;

								a {
									height: 100%;
									display: block;
									width: 100%;

								}
							}
						}
					}

					.owl-nav {
						button {
							width: 50px;
							height: auto;
							bottom: 0;
							top: 0;
							position: absolute;
							border-radius: 0;
							font-size: 0;
							cursor: pointer;
							transition: all 0.25s ease 0s;
							transform: none;
							color: var(--white);
							font-size: 46px;
							line-height: 8px;
							text-align: center;
						}

						.owl-prev {
							/*background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
							background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
							background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
							filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=1);*/
							left: 0;
						}

						.owl-next {
							right: 0;
							/*background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
							background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
							background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
							filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=1);*/
						}
					}
				}

				.img-content {
					.owl-loaded {
						z-index: unset;

						.owl-dots {
							display: none;
						}
					}

					>div {
						height: 100%;
					}

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						z-index: 2;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 15px 0;
				background-color: var(--white);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.unit-title {
					font-size: 20px;
					font-weight: bold;
					color: var(--secondary);
					text-transform: uppercase;

					h3 {
						padding: 0 50px 5px 0;
						word-break: break-all;
					}
				}

				.unit-serv-li {
					li {
						display: inline-block;
						font-size: 13px;
						color: #4A4A4A;
						width: 48%;
						margin-bottom: 10px;

						i {
							margin-right: 5px;
						}
					}
				}


				.unit-place {
					font-size: 13px;
					color: var(--black);

					i.fa {
						color: #868686;
						margin-right: 5px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}
			}

			.unit-price-col {

				background-color: #f3f8fe;
				padding: 15px;
				text-align: center;
				margin: 0 -15px -15px;

				.price-start {
					color: var(--darkgrey);
					margin-bottom: 10px;
					display: block;
				}

				.prices {
					text-align: center;

					.rentRate {
						margin-bottom: 18px;
						display: block;

						span {
							color: var(--darkgrey);
						}

						strong {
							font-size: 24px;
							color: var(--red);
							line-height: 1;
						}

						.main-price {
							strong {
								text-decoration: line-through;
								color: var(--black);
							}
						}
					}

					.price {
						font-size: 20px;
					}
				}
			}
		}

		.unit-btm-dt {
			li {
				display: inline-flex;
				font-size: 14px;
				color: var(--darkgrey);
				width: max-content;
				margin-right: 20px;

				i {
					margin-right: 5px;
				}
			}
		}


	}


	.search-result-properties {
		display: block;

		li {
			font-size: 18px;
			margin-right: 5px;
			display: inline-block;
		}
	}

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.sort-by {

			display: flex;
			align-items: center;

			>.box {
				border: none !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				font-size: 14px;
				color: var(--grey3);
				margin-right: 15px;
			}

			.sort-item {
				color: var(--grey3);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}

		.sort-rest {
			color: var(--grey3);
			font-weight: 600;
		}
	}





	.search-bar {
		padding: 14px;
		border-top: 1px solid var(--light-grey);

		.my-search-token {
			font-size: 12px;
			border-radius: inherit;
			background-color: var(--secondary);
			border: none;
			padding: 3px 6px;

			.close {
				color: var(--white) !important;
				opacity: 1;
				padding: 0 !important;
				text-shadow: none;
				font-size: 16px;
				position: relative !important;
				left: 2px;
				top: 1px;
				display: inline-block !important;
			}
		}

		&.srch-rest {
			.widt-title {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
				margin-top: 0;
			}

			.rest-count {
				color: #a3a3a3;
				font-size: 13px;
				display: block;
				margin-bottom: 5px;
			}

			.text-link {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: 600;
			}
		}

		&:first-child {
			border-top: none !important;
		}



		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			z-index: 50;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			width: 750px;
			max-width: 60%;

			.more-btn-panel {
				display: none;
			}


		}


		.results {

			.unit-row {
				@mixin flexlayout row;

				.info-col {
					width: 46%;
					padding: 0 0 0 15px;
				}

				.unit-price-col {
					width: 22%;
					margin: -15px;
					margin-left: 0;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						position: absolute;
						@mixin size100;


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}
	}

	.toggle-button {
		display: none;

		@media (max-width:992px) {
			position: fixed;
			top: 50vh;
			display: block;
			background-color: var(--secondary);
			padding: 8px 15px;
			z-index: 50;
			left: 0;
			color: var(--white);
			font-size: 24px;
		}
	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}

		.search-m-form {

			@media (min-width:993px) {
				display: none;
			}


			@media (max-width:992px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}
}

#searchpanel {
	.container {
		width: auto;
	}

	@media (min-width:993px) {
		position: relative;
		display: block;
		overflow: visible;

		.close {
			display: none;
		}

		&.modal {
			z-index: 3 !important;
		}

		&.fade {
			opacity: 1;
		}

		.modal-dialog {
			width: auto;
			-webkit-transform: translateY(0%);
			transform: translateY(0%);
			margin-top: 0px;
		}
	}





	@media (max-width:992px) {
		.search-bar {
			border: 1px solid #eee;
			border-top: none;
		}

		&.modal.fade {
			.modal-dialog {
				-webkit-transform: translateX(-100%);
				transform: translateX(-100%);
				position: fixed !important;
			}

			&.in {
				.modal-dialog {
					-webkit-transform: translateX(0);
					transform: translateX(0);
				}
			}
		}
	}

	.modal-dialog {
		@media (max-width:992px) {
			margin: 0;
			padding: 50px 15px 15px;
			z-index: 20001;
			max-width: 370px;
			width: 100%;
			background-color: var(--white);
			overflow-y: scroll;
			height: 100vh;

			>.close {
				color: var(--fff);
				padding: 0 8px 1px;
				position: absolute;
				top: 6px;
				right: 16px;
				z-index: 50;
				opacity: 1;
				font-size: 36px;
				background-color: #ff9a00;
				background-color: var(--primary);
				line-height: 1;
			}
		}

		@media all (min-width: 768px) and (max-width: 991px) {
			max-width: 660px;
		}
	}
}

.vo-search-body {
	.map-section {
		#sidemap {
			z-index: 99;
		}

		.affix {
			top: 30px;
			width: 100%;
			max-width: 23%;
			position: fixed !important;
		}
	}

	.map-pane {
		@media (min-width:768px) {
			height: 80vh !important;
		}
	}

}

@media (min-width: 1840px) {
	.vo-search-body {
		.map-section {
			.affix {
				width: 430px;
				max-width: 100%;
			}
		}
	}
}

@media (max-width: 768px) {
	.map-section {
		#sidebar {
			top: unset !important;
			margin: 30px 0 0 0;
			width: 100% !important;
			position: relative !important;
		}
	}

	.vo-search-body {
		.results {
			.unit-row {
				.map-marker {
					top: 0;
				}
			}
		}
	}
}

.toggle-button2 {
	border-radius: 30px;
	margin-bottom: 30px;
	padding: 15px 30px 15px 30px;
	max-width: 238px;
	width: 100%;
}

@media all and (min-width: 992px) and (max-width: 1199px) {
	.vo-search-body {
		.results {
			.unit-row {
				.unit-price-col {
					width: 25%;

					.btn {
						padding: 8px 12px;
					}
				}
			}
		}

		.map-section {
			.affix {
				max-width: 212px;
			}
		}
	}
}

@media all and (min-width: 1200px) and (max-width: 1300px) {
	.vo-search-body {
		.map-section {
			.affix {
				max-width: 21.5%;
			}
		}
	}
}