:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--primary: #faba00;
	/*--secondary: #6e97cb;*/
	--secondary: #1E75BA;
	--lightblue: #e9eff6;
	--middleblue: #81b1ee;
	--darkblue: #1E75BA;
	--black: #333;
	--white: #fff;
	--bodyfont: 'Poppins', sans-serif;
	--grey: #4e4e4e;
	--light-grey: #f5f5f5;
	--darkgrey: #666666;
	--black2: #333333;
	--green: #01bd86;
	--black3: #14141f;
	--grey2: #b7b7b7;
	--red: #ff0000;
	--grey3: #8a8a8a;
	--black4: #484848;
	--black5: #4d4d4d;
	--asd__day--not-available: #4d4d4d;
}