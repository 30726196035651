@import "common.css";

.unit-view {

	.asd__wrapper--full-screen {

		.asd__month,
		.asd__datepicker-header,
		.asd__days-legend {
			width: 300px !important;
			margin: 0 auto !important;
		}

		.asd__inner-wrapper {
			width: 320px !important;
			margin-left: -236px !important;
		}
	}



	.side-widt-bl {
		.unit-place {
			color: var(--grey3);
			position: relative;
			padding-left: 20px;

			i {
				position: absolute;
				left: 0;
				top: 4px;
			}
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.showLess {
		position: relative;

		&.showFull {
			.unit-widgt-bl {
				display: block;
			}

			.show-hide-btn {
				display: none;

				&:after {
					content: "\f106";
				}
			}
		}

		.unit-widgt-bl {
			display: none;

			&:first-child {
				display: block;
				position: relative;
			}
		}

		.show-hide-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid #dae9fe;

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}



	.owl-carousel-unit {
		position: relative;

		.img-thumbnail {
			border: none;
			padding: 0px;

		}

		.owl-nav {
			position: absolute;
			top: 50%;
			margin-top: -20px;
			width: 100%;

			.owl-prev,
			.owl-next {
				background: #ffffff;
				font-size: 24px;
				line-height: 40px;
				width: 40px;
				height: 40px;
				text-align: center;
				position: absolute;
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}

			.owl-prev {
				left: 15px;
			}

			.owl-next {
				right: 15px;
			}
		}
	}

	.unit-banner {
		position: relative;

		@media (max-width:992px) {
			margin-top: 130px;
		}

		.page-header {
			position: relative;
			bottom: 0;
			width: 100%;
			left: 0;
			z-index: 2;
			margin: 0;
			border-bottom: none;
			padding: 25px 0 30px;
			background-color: var(--darkblue);

			@media (max-width:992px) {
				margin-bottom: 30px;
			}


			h1 {
				font-size: 42px;
				margin-bottom: 0;
				color: var(--white);
				text-transform: capitalize;

				@media (max-width:992px) {
					font-size: 26px;
				}
			}

			.breadcrumb {
				padding: 30px 0;

				@media (max-width:992px) {
					padding: 20px 0
				}

				.breadcrumb-item {
					a {
						color: var(--white);

					}
				}
			}
		}
	}

	.floating-menu {
		background-color: var(--light-grey);

		&.affix {
			position: fixed;
			width: 100%;
			z-index: 3;
			top: 0;
		}

		.fl-menu-itm {
			padding: 20px 10px;
			color: var(--black);
			display: inline-block;
			text-transform: uppercase;

			&:hover {
				color: var(--white);
				background-color: var(--primary);
			}
		}

		@media (max-width:992px) {
			display: none;
		}
	}

	.sidepanel {
		background-color: var(--light-grey);
		font-size: 13px;


		.btn-primary {
			margin-bottom: 15px;
		}

		.side-widt-bl {
			padding: 20px 15px;
			border-bottom: 1px solid #e2e2e2;

			.unit-map {

				height: 280px;
			}

			&:last-child {
				border-bottom: none;
			}

			h5 {
				margin: 0 0 12px;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 600;
			}

			.unit-place {
				color: var(--grey3);
				position: relative;
				padding-left: 20px;

				i {
					position: absolute;
					left: 0;
					top: 4px;
				}
			}

			.contact-bl {
				display: block;
				margin-bottom: 15px;
				position: relative;
				padding-left: 60px;

				.link-tel {
					color: var(--grey);
					font-size: 20px;
					letter-spacing: 1px;
					font-weight: 600;
				}

				i {
					color: var(--primary);
					background-color: var(--white);
					padding: 5px;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					left: 0;
					top: 2px;
					box-shadow: 0 0 10px rgba(0, 0, 0, .18);
					font-size: 18px;

					&.fa-clock-o {
						font-size: 22px;
					}
				}
			}
		}


	}

	.main-section {
		@media (min-width:993px) {
			padding-top: 50px;
		}
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 470px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}

	.unit-calendar {
		.unit-pro-bl {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}

	}

	.unit-widgt-bl {
		margin-bottom: 30px;

		.unt-wt-title {
			background-color: #f3f8ff;
			border: 1px solid #dae9fe;
			text-transform: uppercase;
			padding: 15px;
			margin-bottom: 0;
			border-bottom: none;
		}

		.unit-pro-bl {
			background-color: var(--white);
			padding: 15px;
			border: 1px solid #dae9fe;


			.unit-pro {
				border: 1px solid #dae9fe;
				text-align: center;
				color: var(--grey);
				padding: 15px 0;
				margin-bottom: 15px;
				min-height: 72px;

				.prop-title {
					display: block
				}
			}

			.tick-li {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 20px;
				color: var(--grey);

				li {
					width: 33%;
					padding-left: 30px;
					padding-right: 15px;
					min-height: 30px;
					position: relative;

					@media (max-width:767px) {
						width: 48%;
					}

					@media (max-width:480px) {
						width: 100%;
					}

					&:before {
						content: "\f00c";
						font-family: "Font Awesome 5 Free";
						-webkit-font-smoothing: antialiased;
						font-weight: 600;
						display: inline-block;
						position: absolute;
						left: 0;
						top: 0;
						color: var(--primary);
					}
				}
			}
		}

		.heading4 {
			font-weight: normal;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
	}

	.info-col-main {
		.search-bl {
			position: relative;

			.fa {
				position: absolute;
				right: 0;
				top: 15px;
				color: var(--grey2);
			}
		}

		.form-control {
			border: none;
			border-bottom: 1px solid #e6e6e6;
			text-align: left;
			margin-bottom: 15px;
			padding: 10px 0;
			background-color: var(--white);
		}
	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;

		ul {
			align-items: center;
			flex-wrap: wrap;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			.day {
				width: 30px;
				height: 30px;
				background-color: #7e7975;
				display: inline-block;
				margin: 0 10px;

				&.day-free {
					background: rgba(0, 255, 0, .2);
				}

				&.day-full {
					background: rgba(221, 0, 17, .2);
				}

				&.day-end {
					background: rgba(0, 255, 0, .2);
					background: linear-gradient(135deg, rgba(0, 255, 0, .2) 50%, rgba(221, 0, 17, .2) 50%) !important;
				}

				&.day-start {
					background: #edf4ff;
					background: linear-gradient(135deg, rgba(221, 0, 17, .2) 50%, rgba(0, 255, 0, .2) 0) !important;
				}

				&.day-disable {
					background: rgba(0, 255, 0, .2);
				}

			}
		}
	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 0;

				p {
					color: var(--grey);
					margin-bottom: 30px;
				}
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.flow-buttons {
			button {
				margin-left: 20px;
			}
		}

		@media (min-width:768px) {
			.info-col.noaction {
				position: absolute;
				right: 0;
				top: -41px;

				width: 33.33333333%;
				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

				.info-col-header {
					height: 40px;
					padding: 1px;
					background-color: rgba(60, 63, 64, 0.9);
					color: #fff;
					text-align: center;

					h2 {
						margin-top: 10px;
						font-size: 18px;
					}
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}

				.fav-section {
					margin-top: 20px;
					border-bottom: 1px solid #888 !important;
					padding: 15px;
				}

			}
		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}


			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}




		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.v-img-gallery {
	display: flex;
	flex-wrap: wrap;
	margin: 20px 0;

	a {
		position: relative;
		display: inline-block;
		width: 20%;
		padding: 5px;

		&:nth-child(n + 6) {
			display: none;
		}

		&:nth-child(5) {
			&:before {
				z-index: 1;
				content: "weitere Bilder";
				position: absolute;
				color: #fff;
				background-color: rgba(0, 0, 0, 0.5);
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				text-align: center;
				display: inline-block;
				padding-top: 27%;
				font-weight: bold;
			}
		}
	}

	.img-thumbnail {
		border: none;
		padding: 0;
		width: 100%;

	}

}

/*.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}*/

.single-unit {
	margin: 20px 0;

	.unit-img {
		img {
			width: 100%;
		}
	}

	.unit-content {
		padding: 15px;
		background-color: var(--white);

		@media (min-width:768px) {
			min-height: 200px;
		}

		.unit-title {
			color: var(--secondary);
		}

		.unit-serv-li {
			min-height: 25px;

			li {
				display: inline-block;
				font-size: 13px;
				color: #4A4A4A;
				width: 48%;
				margin-bottom: 10px;

				@media (max-width:992px) {
					display: block;
					width: auto;
				}

				i {
					margin-right: 5px;
				}
			}
		}

		.search-result-properties {
			display: block;
			min-height: 25px;

			li {
				font-size: 18px;
				margin-right: 5px;
				display: inline-block;


			}
		}

	}

	.btn-primary {
		color: var(--white);
	}
}



@media print {

	*,
	*:before,
	enter code here *:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		margin: 0;
		padding: 0
	}

	a[href]:after {
		content: none !important;
	}

	a,
	a:visited {
		text-decoration: underline
	}

	a[href]:after {
		content: " ("attr(href) ")"
	}

	abbr[title]:after {
		content: " ("attr(title) ")"
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}

	.header-print {
		left: 0;
		position: fixed;
		top: ;
		display: table-header-group;
	}

	.unit-view .showLess .unit-widgt-bl {
		display: block !important;
	}

	.show-more,
	.owl-nav {
		display: none;
	}

	.v-img-gallery {
		display: block !important;

		a {
			float: left;

			.img-thumbnail {
				width: 20% !important;
				margin: 10px;


			}
		}
	}


	p {
		font-size: 13px
	}

	h3 {
		font-size: 16px
	}


	.hidden-print {
		display: none;
		visibility: hidden
	}

	.v-img-gallery {
		margin: 20px 0;

		a {
			&:nth-child(n + 6) {
				display: none;
			}
		}


	}



	/*ul {
		li:nth-of-type(1n+4) {
			display: none;
		}
	}*/

	@page {
		margin: 2mm;
	}

	.print-gap {
		margin-top: 80px;
	}

	.unit-view {
		padding-top: 10px
	}

	.page-brake {
		page-break-after: always
	}

	.text-blue {
		color: #0060a1 !important
	}

	.txt-orange {
		color: #fa590c !important
	}

	.text-grey {
		color: #797979 !important
	}
}

@media (max-width: 992px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 500px;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 767px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 350px;
				object-fit: cover;
			}
		}
	}
}