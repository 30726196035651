.static {
	display: block;
}

.region-view {
	@media (max-width:992px) {
		padding-top: 40px;
	}
}

.inner-banner {
	background-image: url(RESOURCE/img/bg-banner-contact.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 320px;
	position: relative;

	@media (max-width:992px) {
		height: 320px;
		margin-top: 140px;
	}


	.page-title {
		position: absolute;
		bottom: 70px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;

		@media (max-width:992px) {
			bottom: 30px;
		}


		h1 {
			font-size: 42px;
			margin-bottom: 0;
			color: var(--white);
			text-transform: uppercase;

			@media (max-width:992px) {
				font-size: 30px;
			}
		}
	}
}

.teams-bl-new {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 20px 0 20px 0;

	.col-md-2 {
		border: 1px solid #f4f4f4;
		padding: 15px;
		margin: 0;
	}

	p {
		strong {
			line-height: 30px;
			font-size: 16px;
		}
	}
}

.teams-bl {
	/*max-width: 1040px;*/
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.team-bl {
		.team-dt {
			border: 1px solid #e8e8e8;
			border-top: none;
			padding: 20px 8px;
			text-align: center;
			min-height: 130px;
		}

		img {
			width: 100%;
			height: 200px;
			object-fit: cover;
		}

		h4 {
			line-height: 28px;
		}

		p {
			line-height: 23px;
			font-size: 12px;
		}
	}

}

.vermieter {
	h1 {
		font-size: 24px;
		padding-top: 36px;

	}

	h2 {
		font-size: 18px;
		padding-top: 36px;
		color: var(--secondary);
	}

	h3 {
		font-size: 18px;
		margin-bottom: 5px;
		padding-top: 18px;
	}

	ul {
		margin-left: 20px;

		li {
			padding-bottom: 18px;
			font-weight: 300;
		}
	}

	.fa-check {
		color: var(--secondary);
	}

	.section-title {
		.heading2 {
			text-transform: none;
			padding-top: 0px;
		}
	}

	a {
		color: var(--black);
		text-decoration: underline;
	}
}